import React from 'react';
import { Row, Col, Layout } from 'antd';
const { Content } = Layout;

import {
  UserOutlined,
  EditOutlined,
  PhoneOutlined,
  MailOutlined,
  CameraOutlined,
} from '@ant-design/icons';
import { Typography, List, Avatar, Empty } from 'antd';
import '../styles/profile.scss';
import { useSelector } from 'react-redux';
import HeaderComponent from '../components/header/header';

const { Text, Title, Link } = Typography;

const Profile = () => {
  const user = useSelector(state => state.user);
  const data = [];

  const metaData = [
    {
      key: 'contact',
      sub: 'Mobile',
      icon: <PhoneOutlined/>,
    },
    {
      key: 'email',
      sub: 'Email',
      icon: <MailOutlined/>,
    },
    {
      key: 'idPhoto',
      sub: 'Cédula, pasaporte, licencia de conducir subir fotos',
      icon: <CameraOutlined/>,
    },
  ];

  metaData.map(item => {
    Object.keys(user).map(key => {
      if (key === item.key)
        data.push({ ...item, title: user[key] });
    });
  });

  return (
    <Layout>
      <HeaderComponent title="Perfil" goTo="/home" />
      <Content className="site-layout-background">
        <div className="content-profile">
          <Row align="middle" gutter={[0, 15]}>
            <Col offset={8} span={8} align="center">
              <Avatar size={60} className="avatar-sty" icon={<UserOutlined />} />
            </Col>
            <Col span={8} align="start">
              <Link href="/edit-profile">
                <EditOutlined style={{ fontSize: '20px' }} />
              </Link>
            </Col>
          </Row>
          <Col align="center">
            { user.fullname ?
              <Title level={4} className="title-section">
                {user.fullname}
              </Title>
              : ''}
            {user.ci ? <Text className="title-section">CI: {user.ci}</Text> : ''} 
          </Col>
          <List
            className="list-profile"
            itemLayout="horizontal"
            dataSource={data}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Link>{item.icon}</Link>}
                  title={<Link>{item.title}</Link>}
                  description={item.sub}
                />
              </List.Item>
            )}
          />
          {user.idPhoto ?
            <Row>
              <Col span={24} align="center">
                <Empty description="" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                <Text className="title-section">Frente</Text>
              </Col>
              <Col span={24} align="center">
                <Empty description="" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                <Text className="title-section">Posterior</Text>
              </Col>
            </Row>
            : ''}
        </div>
      </Content>
    </Layout>
  );
};

export default Profile;
